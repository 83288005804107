@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@300&family=Source+Sans+Pro:wght@300&display=swap");
* {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

html {
  height: 100%;
}

html body {
  margin: 0;
  padding: 0;
  width: 100%;
  background-color: #f6f9fc;
  background-size: cover;
  background-repeat: no-repeat;
  font-family: "Raleway", sans-serif;
  -webkit-text-size-adjust: 100%;
}

.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.main-container {
  max-width: 1100px;
  margin: 0 auto;
  padding: 1rem;
}

.nav-container {
  padding: 1rem;
  max-width: 1100px;
  margin: 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-preferred-size: 500px;
      flex-basis: 500px;
  -webkit-box-flex: 3;
      -ms-flex-positive: 3;
          flex-grow: 3;
}

.nav-container a {
  text-decoration: none;
  color: #000;
  padding: 1rem;
  -webkit-transition: color 0.6s ease;
  transition: color 0.6s ease;
}

.nav-container a:hover {
  cursor: pointer;
  color: #8676de;
}

.nav-container .contact a {
  padding: 0.5rem;
  -webkit-transition: color 0.6s ease;
  transition: color 0.6s ease;
}

.nav-container .contact a:hover > svg {
  fill: #8676de;
}

.hero-container {
  margin: 3rem 0 9rem;
  font-family: Consolas, monaco, monospace;
}

.hero-container h1 {
  color: #705dd8;
  font-size: 5rem;
  font-weight: 300;
}

.hero-container h1 .cursor {
  font-size: 90%;
  color: #705dd8;
  -webkit-animation: cursor-blink 1s steps(1) infinite;
          animation: cursor-blink 1s steps(1) infinite;
}

.hero-container .about {
  font-family: "Raleway", sans-serif;
  font-size: 1.3rem;
}

.hero-container .terminal-nav {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  width: 100%;
  height: 1.5rem;
  background-color: #a8a8a8;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}

.hero-container .terminal-nav .btn {
  width: 0.8rem;
  height: 0.8rem;
  margin: 0.4rem;
  border-radius: 50%;
}

.hero-container .terminal-nav .btn.red {
  background-color: red;
}

.hero-container .terminal-nav .btn.green {
  background-color: green;
}

.hero-container .terminal-nav .btn.yellow {
  background-color: #ffd000;
}

.hero-container .my-info {
  background-color: #414141;
  padding: 1rem;
  -webkit-box-shadow: 0 50px 100px rgba(50, 50, 93, 0.15), 0 15px 35px rgba(50, 54, 93, 0.2), 0 5px 15px rgba(0, 0, 0, 0.12);
          box-shadow: 0 50px 100px rgba(50, 50, 93, 0.15), 0 15px 35px rgba(50, 54, 93, 0.2), 0 5px 15px rgba(0, 0, 0, 0.12);
  font-size: 18px;
  color: #fff;
}

.hero-container .my-info .skill {
  color: #65e280;
}

.hero-container .my-info .info-section {
  padding: 1rem 0;
}

.hero-container .my-info .info-section a {
  color: #fff700;
  text-decoration: none;
}

.hero-container .my-info .info-section .info-data {
  color: #8486ff;
}

.hero-container .my-info .info-section a:hover {
  color: #8676de;
}

.hero-container .my-info .cursor {
  font-size: 90%;
  color: #fff;
  -webkit-animation: cursor-blink 1s steps(1) infinite;
          animation: cursor-blink 1s steps(1) infinite;
}

.hero-container .info-title::before,
.hero-container .hero-container h1::before {
  content: "> ";
}

.title-container p {
  font-size: 1.3rem;
}

.card-container {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (minmax(300px, 1fr))[auto-fill];
      grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-auto-rows: auto;
  grid-gap: 6rem;
  margin: 0;
}

.card-container .card {
  overflow: hidden;
  font-size: medium !important;
  border-radius: 10px;
  background-color: #fff;
  -webkit-box-shadow: 0 50px 100px rgba(50, 50, 93, 0.05), 0 15px 35px rgba(50, 50, 93, 0.1), 0 5px 15px rgba(0, 0, 0, 0.1);
          box-shadow: 0 50px 100px rgba(50, 50, 93, 0.05), 0 15px 35px rgba(50, 50, 93, 0.1), 0 5px 15px rgba(0, 0, 0, 0.1);
}

.card-container .card .full-height {
  height: 100%;
}

.card-container .card .project-img {
  height: 250px;
  min-height: 250px;
  max-height: 250px;
  min-width: 300px;
  max-width: 550px;
  position: relative;
}

.card-container .card .project-img img {
  height: 100%;
  width: 100%;
}

.card-container .card .project-info {
  position: relative;
  height: 400px;
}

.card-container .card .project-title,
.card-container .card .project-description {
  margin: 0;
  padding: 1rem;
}

.card-container .card .skills {
  margin: 2rem 1rem;
  padding: 0;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  list-style-type: none;
}

.card-container .card .skills li {
  height: auto;
  margin-right: 0.5rem;
  font-weight: 600;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border: 2px solid #585bfa;
  border-radius: 5px;
  padding: 5px;
  margin-bottom: 2px;
}

.card-container .project-btn {
  position: absolute;
  bottom: 0;
  margin: 1rem;
}

.card-container .project-btn .btn {
  margin-right: 1rem;
  padding: 16px 12px;
  -webkit-box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
          box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  line-height: 1.25;
  border-radius: 5px;
  text-decoration: none;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.08em;
  position: relative;
  -webkit-transition: background-color 0.6s ease;
  transition: background-color 0.6s ease;
  overflow: hidden;
}

.card-container .project-btn .btn .icon {
  margin-left: 0.2rem;
}

.card-container .project-btn .btn a {
  margin: 0.5rem;
  padding: 0.7rem;
}

.card-container .project-btn .btn.src {
  color: #fff;
  background: #705dd8;
}

.card-container .project-btn .btn.src:hover {
  background: #8676de;
  -webkit-transform: translate(0, -3px);
          transform: translate(0, -3px);
}

.card-container .project-btn .btn.demo {
  color: #705dd8;
  background: #f1f1f1;
}

.card-container .project-btn .btn.demo:hover {
  background: white;
  -webkit-transform: translate(0, -3px);
          transform: translate(0, -3px);
}

.img-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.img-overlay svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 32px;
}

.img-overlay .shape-fill {
  fill: #ffffff;
}

.img-overlay .fill-two {
  fill: #585bfa;
}

footer {
  margin: 4rem 0 auto;
  width: 100%;
  background-color: #705dd8;
  color: #f1f1f1;
  padding: 0.5rem;
}

footer .footer-info {
  max-width: 1100px;
  margin: 0 auto;
  padding: 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-preferred-size: 500px;
      flex-basis: 500px;
  -webkit-box-flex: 3;
      -ms-flex-positive: 3;
          flex-grow: 3;
  text-align: center;
}

footer .footer-info .contact a {
  padding: 0.5rem;
  -webkit-transition: color 0.6s ease;
  transition: color 0.6s ease;
}

footer .footer-info .contact a:hover > svg {
  fill: white;
}

/** For mobile devices **/
@media (max-width: 767px) {
  .img-overlay svg,
  footer-bg svg {
    width: calc(100% + 1.3px);
    height: 38px;
  }
}

@media screen and (min-width: 703px) and (max-width: 810px) {
  .card-container {
    grid-gap: 2rem;
  }
  .card {
    width: 100%;
  }
}

@media screen and (max-width: 702px) {
  .project-btn {
    position: relative !important;
  }
  .project-info {
    height: auto !important;
  }
}

@media screen and (min-width: 582px) and (max-width: 702px) {
  .card {
    width: 82%;
    margin: 0 auto;
  }
}

@media screen and (max-width: 460px) {
  html,
  body {
    text-size-adjust: none;
    -webkit-text-size-adjust: none;
    -moz-text-size-adjust: none;
    -ms-text-size-adjust: none;
  }
  .nav-container,
  .footer-info {
    -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
            justify-content: center !important;
  }
}

@media screen and (max-width: 450px) {
  .project-info {
    grid-gap: 0;
    margin: 0;
  }
  .project-img {
    height: 200px;
  }
  .card {
    height: auto;
  }
}

@media screen and (max-width: 400px) {
  .project-img {
    display: none !important;
  }
  .hero-container {
    margin: 4rem 0;
  }
  .name {
    margin-top: 0;
    font-size: 4rem !important;
  }
}

/*Cursor Animation*/
@-webkit-keyframes cursor-blink {
  50% {
    color: transparent;
  }
}
@keyframes cursor-blink {
  50% {
    color: transparent;
  }
}
